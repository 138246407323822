

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(-45deg, #7ad6e848, #7ad6e838, #b2dcf754, #b2dcf764);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100vh;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .App {
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    padding: 24px 12px;
    flex-direction: column;
    box-sizing: border-box;
  }
  
  .CommentForm {
    background: #fff;
    padding: 16px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
    max-width: 100%;
    box-sizing: border-box;
  }
  
  .CommentForm__Textarea {
    font-size: 16px;
    height: 100px;
    width: 400px;
    max-width: 100%;
    max-height: 100px;
    box-sizing: border-box;
    font-family: inherit;
    background: #f1f1f1;
    border: solid 2px #f1f1f1;
    padding: 12px;
    border-radius: 5px;
    transition: border-color 0.3s ease 0s;
    display: block;
  }
  
  .CommentForm__Textarea:focus {
    border-color: #3471ea;
  }
  
  .CommentForm__Actions {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
  }
  
  .CommentForm__Submit {
    background-color: #1A8CD8;
    color: #fff;
    height: 36px;
    line-height: 36px;
    min-width: 100px;
    width: 300px;
    margin-left: 10%;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease 0s;
  }
  
  .CommentForm__Submit:hover {
    background-color: #245bc1;
  }