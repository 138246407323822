p{
  color: rgb(44, 44, 44)
}
ul{
  padding: 0px;
}

.instructions{
  display: block;
  position: absolute;
  width: 400px;
  float: left;
  left: 20px;
  font-family: Georgia, serif;
}

@keyframes ripple {
    0% {
        background-position: 0% 0%;
    }

    50% {
        background-position: 0% 100%;
    }

    100% {
        background-position: 0% 0%;
    }
}

body {
    animation: ripple 5s infinite;
    background: linear-gradient(to bottom, #FFFFFF, #E0F2FF);
    background-size: 100% 200%; /* This ensures our gradient covers twice the height of the element */
}
.submit-button::after {
    content: '';
    display: block;
    margin: 15px auto; /* gives space above the line and centers the line below the button */
    width: 75%;
    height: 1px;
    background-color: lightgrey;
}
label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
}

.results {
    padding-top: 15px;
    display: flex; /* turns on flexbox */
    align-items: center; /* vertically center the traits and rectangles */
}

    /* space between each trait */
    .results > div {
        margin-right: 30px; /* adjust as needed */
    }

.postContent {
    background-color: black;
    padding: 20px;
    border-radius: 5px; /* This gives a rounded corner look. You can adjust or remove it. */
}

    .postContent p {
        color: white;
        margin: 0; /* This removes any default margins around the paragraph to ensure the padding is consistent. */
    }

.post_body {
    background: #fff;
    padding: 16px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
    max-width: 100%;
    box-sizing: border-box;
    margin-bottom: 120px;

  }
  
  input,.CommentForm__Textarea {
    font-size: 16px;
    height: 100px;
    width: 400px;
    max-width: 100%;
    max-height: 100px;
    box-sizing: border-box;
    font-family: inherit;
    background: #f1f1f1;
    border: solid 2px #f1f1f1;
    padding: 12px;
    border-radius: 5px;
    transition: border-color 0.3s ease 0s;
    display: block;
  }

  button{
      margin-top: 5%;
      margin-bottom: 5%;
  }
  .CommentForm__Textarea:focus {
    border-color: #112346;
  }
  
  .CommentForm__Actions {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
  }